.container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.box {
  background-color: #fff;
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 30px 30px;
  box-sizing: border-box;
  text-align: center;
}

.title {
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.calender {
  margin: 20px 0;
  width: 100%;
}
